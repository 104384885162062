var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DataDictionaryOptionForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _vm.editType === 2
                    ? _c("el-form-item", { attrs: { label: "父类" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.parentName))]),
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "字典选项",
                        prop: "value",
                        rules: [
                          {
                            required: true,
                            message: "请输入选项名称",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入选项名称",
                          maxlength: 20,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "value", $$v)
                          },
                          expression: "form.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.editType !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选项key",
                            prop: "code",
                            rules: [
                              {
                                required: true,
                                message: "请输入选项key",
                                trigger: "change",
                              },
                              {
                                required: true,
                                validator: _vm.validateCode,
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              placeholder: "请输入选项key",
                              maxlength: 20,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("el-form-item", { attrs: { label: "选项key" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.form.code))]),
                      ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序",
                        prop: "sort",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          max: 9999,
                          min: 0,
                          placeholder: "请输入排序",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }