<template>
  <div class="DataDictionaryOptionForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <el-form-item v-if="editType === 2" label="父类" >
            <span>{{parentName}}</span>
          </el-form-item>
          <el-form-item label="字典选项" prop="value" :rules="[{ required: true, message: '请输入选项名称', trigger: 'change'}]">
            <v-input v-model="form.value" placeholder="请输入选项名称" :maxlength="20" :width="width"/>
          </el-form-item>
          <el-form-item v-if="editType !== 1" label="选项key" prop="code"
            :rules="[{ required: true, message: '请输入选项key', trigger: 'change'},
            {required: true, validator:validateCode, trigger: 'change'},]">
            <v-input v-model="form.code" placeholder="请输入选项key" :maxlength="20" :width="width"/>
          </el-form-item>
          <!-- 编辑状态key不可以修改 -->
          <el-form-item v-else label="选项key" >
            <span>{{form.code}}</span>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :rules="[{ required: true, message: '请输入排序', trigger: 'blur'}]">
            <v-input-number :max="9999" :min="0" placeholder="请输入排序" v-model="form.sort" :width="width" />
          </el-form-item>
          <!-- <el-form-item label="状态" prop="status" :rules="[{ required: true, message: '请选择状态', trigger: 'change'}]">
            <v-select v-model="form.status" placeholder="请选择状态" :options="statusOps" :width="width"/>
          </el-form-item> -->
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryDetailURL, createURL, updateURL, createChildURL } from './api'
import { setStatusOps, editTypeMap } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'
const codeRegular = /^[A-Za-z0-9!@#_]{1,20}$/
export default {
  name: 'DataDictionaryOptionForm',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 200,
      submitConfig: {
        submitUrl: '',
        submitMethod: 'POST',
        queryUrl: queryDetailURL
      },
      statusOps: setStatusOps(),
      form: {
        id: undefined,
        value: '',
        code: '',
        sort: undefined
        // status: undefined
      },
      // 默认是新增 0新增 1编辑 2添加子类
      editType: 0,
      supName: '',
      supId: undefined
    }
  },

  created () {
    const { parentName, editType } = this.$route.query
    this.editType = parseInt(editType || 0)
    this.parentName = parentName || ''
    if (this.editType === 0) {
      // 新增主库字典
      this.submitConfig.submitUrl = createURL
      // 数据字典类型id，最外层带进来
      this.form.codewordTypeId = this.$route.query.typeId
    } else if (this.editType === 1) {
      // 编辑
      this.submitConfig.submitUrl = updateURL
    } else if (this.editType === 2) {
      // 添加子类
      this.submitConfig.submitUrl = createChildURL
      // 数据字典类型id，最外层带进来
      this.form.codewordTypeId = this.$route.query.typeId
      // 父类id
      this.form.parentId = this.$route.query.parentId
    }
    let title = editTypeMap[this.editType]
    this.$setBreadcrumb(title)
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id })
    }
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
    },
    submitBefore (data) {
      let postData = {}
      Object.keys(data).forEach(item => {
        if (data[item] !== undefined) {
          postData[item] = data[item]
        }
      })
      return postData
    },

    validateCode (rule, value, callback) {
      if (value && !codeRegular.test(value)) {
        callback(new Error('请输入正确的选项值'))
      } else {
        callback()
      }
    }
  }
}
</script>
